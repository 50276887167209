<template>
  <Loader style="flex-grow: 1" class="contact-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ExtractString } from '@/utils'
// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  computed: {
    ...mapState('Project', ['item']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return {}
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('leadFormTitle'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  .contact-list-lead-layout {
    @include respond-below('phone') {
      gap: 4rem !important;
      flex-direction: column-reverse !important;
    }
  }

  .lead-content {
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    p:last-child {
      margin-bottom: 0.75em;
    }
  }
}
</style>
